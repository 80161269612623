import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import "./orbiwiki.css";

const Orbiwiki = () => {
    const navigate = useNavigate()
    const openDocs = () => {
        navigate('/contact')
    };

    const goBack = () => {
        navigate('/')

    };

    const scrollToRoadmap = () => {
        document.querySelector('.roadmap').scrollIntoView({ behavior: 'smooth' });
    };

    const roadmapItems = [
        {
            phase: "Phase 1",
            title: "Orbiwiki Documentation",
            description: "Empowering your legal work with a personalized, always-accessible documentation hub—streamlined and built just for you.",
        },
        {
            phase: "Phase 2",
            title: "AI-Powered Assistance",
            description: "Revolutionize your workflow with AI-integrated support. Ask questions, get insights, and chat directly about your legal documents.",
        },
        {
            phase: "Phase 3",
            title: "Innovation Ahead",
            description: "Stay tuned for groundbreaking features that redefine how you manage and interact with your legal resources.",
        },
        {
            phase: "Phase 4",
            title: "Future Evolution",
            description: "Pioneering the next frontier of legal tech with token-gated access and seamless metaverse integration.",
        },
    ];


    return (
        <div className="container">
            <div className="gradient-bg">
                <div className="gradient-circle-1" />
                <div className="gradient-circle-2" />
                <div className="gradient-circle-3" />
            </div>

            <motion.button
                className="back-button"
                onClick={goBack}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
            >
                ← Back
            </motion.button>

            <motion.section
                className="hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <motion.div
                    className="hero-content"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.8 }}
                >
                    <div className="hero-title-container">
                        <h1 className="hero-title">OrbiWiki</h1>
                        <div className="hero-badge">Beta</div>
                    </div>
                    <p className="hero-subtitle">
                        Decentralized Legal Web3 Documentation Platform
                    </p>
                    <div className="button-group">
                        <motion.button
                            className="launch-button"
                            onClick={openDocs}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <span className="button-text">Request Demo</span>
                            <span className="button-icon">→</span>
                        </motion.button>
                        <motion.button
                            className="roadmap-button"
                            onClick={scrollToRoadmap}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <span className="button-text">View Roadmap</span>
                            <span className="button-icon">↓</span>
                        </motion.button>
                    </div>
                </motion.div>
            </motion.section>

            <motion.section
                className="roadmap"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 1 }}
            >
                <h2 className="roadmap-title">Development Roadmap</h2>
                <div className="timeline">
                    {roadmapItems.map((item, index) => (
                        <motion.div
                            key={index}
                            className="timeline-item"
                            initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6, delay: index * 0.2 }}
                        >
                            <div className="timeline-content">
                                <div className="phase-badge">{item.phase}</div>
                                <h3 className="timeline-title">{item.title}</h3>
                                <p className="timeline-description">{item.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </motion.section>
        </div>
    );
};

export default Orbiwiki;
