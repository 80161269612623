import React, { useState } from "react";
import { motion } from "framer-motion";
import "./contact.css";

const Contact = () => {
    const [flippedCards, setFlippedCards] = useState([]);

    const handleCardClick = (index) => {
        setFlippedCards((prev) => {
            const newFlips = [...prev];
            newFlips[index] = !newFlips[index];
            return newFlips;
        });
    };

    const teamMembers = [
        {
            name: "Ross Belhomme",
            role: "Lawyer",
            image: "https://media.licdn.com/dms/image/v2/C4E03AQHWhuL_OuKK6A/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1653127311088?e=1742428800&v=beta&t=6RRGPN3ypVKnpJxLSxfeNKDmlC7-fnwZdFQgIH_GDbA",
            banner: "https://media.licdn.com/dms/image/v2/D4E16AQFxBh9PNyGY1A/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1697728704508?e=1742428800&v=beta&t=MG1BKvvg8PJx96ICNUG5H61dDXZg61-90Rb7LPFkW1s",
            socials: {
                twitter: "https://x.com/sarahchen",
                linkedin: "https://www.linkedin.com/in/rossbelhomme/",
                telegram: "https://t.me/sarahchen",
            },
            introduction: "I’m a crypto lawyer and author with a knack for decoding blockchain regulations and making compliance seamless. My mission? To help you stay legally secure while exploring the limitless potential of Web3.",
        },
        {
            name: "Maxime Billen",
            role: "Product Manager",
            image: "https://media.licdn.com/dms/image/v2/D4D03AQEtJUn6r8Pjhw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1718292846986?e=1742428800&v=beta&t=E6vb3VPpdbs6WDrJlO5z68dw9RB9xcZtDKDFaba6eQY",
            banner: "https://media.licdn.com/dms/image/v2/D4D16AQGeJd7iX_saXQ/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1719347689008?e=1742428800&v=beta&t=kyoDyg8iXHM3IEKkM07pb6hpWSDyjrSQCNS5D1GFK6s",
            socials: {
                twitter: "https://x.com/alexrivera",
                linkedin: "https://www.linkedin.com/in/maximebillen/",
                telegram: "https://t.me/alexrivera",
            },
            introduction: "I bring blockchain and deep tech consulting together with a people-first approach. From untangling complexity to delivering exceptional experiences, I make sure your Web3 projects are as smooth as they are innovative.",
        },
        {
            name: "Deniz Karatas",
            role: "Developer",
            image: "https://media.licdn.com/dms/image/v2/D4E03AQE7hka9G3nfvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724393485653?e=1742428800&v=beta&t=RImsWgJi9rriVCjam4qYwhlyQOyDcm-J265YlbTtT48",
            banner: "https://media.licdn.com/dms/image/v2/D4E16AQFRGZTSJApdcg/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1732202453984?e=1742428800&v=beta&t=Kjxj85ufUy-lM0VIt6e0uIX4Iknmxz6UdSEg9e59Zik",
            socials: {
                twitter: "https://x.com/mayapatel",
                linkedin: "https://www.linkedin.com/in/deniz-karatas/",
                telegram: "https://t.me/mayapatel",
            },
            introduction: "As a Solana developer fully immersed in the Web3 space, I specialize in crafting scalable blockchain solutions and innovative dApps. Think of me as the tech wizard turning your big ideas into decentralized reality.",

        },
    ];

    return (
        <div className="contact-container">
            <motion.h1
                className="contact-title"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                Meet Our Team
            </motion.h1>

            <div className="team-grid">
                {teamMembers.map((member, index) => (
                    <motion.div
                        key={index}
                        className={`team-card ${flippedCards[index] ? "flipped" : ""}`}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                        onClick={() => handleCardClick(index)}
                    >
                        <div className="team-card-inner">
                            <div className="team-card-front">
                                <div className="banner-container">
                                    <img
                                        src={member.banner}
                                        alt={`${member.name} banner`}
                                        className="member-banner"
                                    />
                                </div>
                                <div className="member-info">
                                    <div className="member-image-container">
                                        <img
                                            src={member.image}
                                            alt={member.name}
                                            className="member-image"
                                        />
                                    </div>
                                    <h2 className="member-name">{member.name}</h2>
                                    <p className="member-role">{member.role}</p>
                                    <div className="social-links">
                                        <motion.a
                                            href={member.socials.twitter}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="social-link twitter"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            𝕏
                                        </motion.a>
                                        <motion.a
                                            href={member.socials.linkedin}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="social-link linkedin"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            in
                                        </motion.a>
                                        <motion.a
                                            href={member.socials.telegram}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="social-link telegram"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            ✈
                                        </motion.a>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card-back">
                                <div className="back-content">
                                    <h2>About {member.name}</h2>
                                    <p>{member.introduction}</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Contact;