import React from 'react';
import Spline from '@splinetool/react-spline';

import './introduction.css';

function Introduction() {
    return (
        <section className="introduction">
            <div className="intro-elements">
                <div className="intro-text">
                    We secure the legal,<br /> you power the tech.
                </div>

                <div className='spline-resize'>
                    <Spline className="spline-scene-home" scene="https://prod.spline.design/F-zJmawgpj-sHVcn/scene.splinecode" />
                </div>
            </div>
        </section>
    );
}

export default Introduction;
