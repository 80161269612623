import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../Pages/Home/Home';
import Contact from '../Pages/Contact/Contact';
import Orbiwiki from '../Pages/Orbiwiki/Orbiwiki';
import BookCall from '../Pages/BookCall/BookCall';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orbiwiki" element={<Orbiwiki />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/call" element={<BookCall />} />
            <Route path="*" element={<Navigate to="/" />} />

        </Routes>
    );
};

export default AppRoutes;
