import React from 'react';
import { motion } from 'framer-motion';
import './about.css';

const About = () => {
    const features = [
        {
            title: "Expert Legal Guidance",
            description: "We offer specialized consulting and general counsel tailored to the fast-paced blockchain landscape. Our team delivers clear, actionable guidance on everything from compliance strategies to risk management.",
            icon: "⚖️"
        },
        {
            title: "Compliance Focus",
            description: "With us handling the complexities, you're free to focus on innovation, confident that your legal foundation is secure. We ensure your project stays on solid legal ground.",
            icon: "✓"
        },
        {
            title: "Fast Documentation",
            description: "We provide custom paperwork solutions so you can easily manage crucial steps, from contracts to regulatory filings, saving you time and ensuring smooth, uninterrupted progress.",
            icon: "📄"
        }
    ];

    return (
        <section className="about-container">
            {/* Gradient Background Circles */}
            <div className="gradient-bg">
                <div className="gradient-circle-1" />
                <div className="gradient-circle-2" />
                <div className="gradient-circle-3" />
            </div>

            <motion.h1
                className="about-title"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                About Us
            </motion.h1>

            <motion.p
                className="about-subtitle"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            >
                Expert Legal Guidance for Compliance and Growth Beyond Paperwork
            </motion.p>

            <div className="features-grid">
                {features.map((feature, index) => (
                    <motion.div
                        key={index}
                        className="feature-card"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }} // Trigger animation once on scroll
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                    >
                        <div className="feature-icon">{feature.icon}</div>
                        <h2 className="feature-title">{feature.title}</h2>
                        <p className="feature-description">{feature.description}</p>
                    </motion.div>
                ))}
            </div>

            <motion.div
                className="cta-section"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.6 }}
            >
                <h2 className="cta-title">Ready to Secure Your Project's Future?</h2>
                <motion.button
                    className="cta-button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Get Started →
                </motion.button>
            </motion.div>
        </section>
    );
};

export default About;
