import React from 'react';
import { motion } from 'framer-motion';
import './whyus.css';

const WhyUs = () => {
    const benefits = [
        { title: "Tailored Crypto Solutions", text: "Custom legal strategies for your blockchain projects.", icon: "🛠️" },
        { title: "Regulatory Compliance", text: "Navigate complex global regulations with ease.", icon: "📜" },
        { title: "Asset Protection", text: "Secure your digital assets against legal risks.", icon: "🔒" },
        { title: "DeFi Expertise", text: "Specialized knowledge in decentralized finance frameworks.", icon: "🌐" },
        { title: "Global Reach", text: "Support for clients across borders and jurisdictions.", icon: "✈️" },
        { title: "24/7 Support", text: "Dedicated team available anytime, anywhere.", icon: "⏰" }
    ];

    return (
        <div className="whyus-container">
            {/* Gradient Background */}
            <div className="gradient-bg">
                <div className="gradient-circle-1" />
                <div className="gradient-circle-2" />
                <div className="gradient-circle-3" />
            </div>

            <div className="whyus-content">
                {/* Left Column */}
                <motion.div
                    className="whyus-left"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <h2 className="whyus-heading">Why Choose Orbilaw?</h2>
                    <div className="whyus-blocks">
                        <motion.div
                            className="whyus-block"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2, duration: 0.6 }}
                        >
                            <h3 className="whyus-subheading">Web3 Challenges</h3>
                            <p className="whyus-text">
                                Web3 can feel daunting. Whether you're navigating blockchain regulations, developing decentralized applications, or managing tokenized assets, the challenges are real—and costly if handled wrong.
                            </p>
                        </motion.div>
                        <motion.div
                            className="whyus-block"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4, duration: 0.6 }}
                        >
                            <h3 className="whyus-subheading">Our Expertise</h3>
                            <p className="whyus-text">
                                You'll work with seasoned professionals specializing in blockchain legal compliance, smart contract law, and digital asset protection.
                            </p>
                        </motion.div>
                        <motion.div
                            className="whyus-block"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.6, duration: 0.6 }}
                        >
                            <h3 className="whyus-subheading">Your Success, Our Priority</h3>
                            <p className="whyus-text">
                                It's all about you—your goals, your challenges, your future. We help you build trust and efficiency into your journey.
                            </p>
                        </motion.div>
                    </div>
                </motion.div>

                {/* Right Column: Benefits */}
                <motion.div
                    className="whyus-right"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <div className="benefits-grid">
                        {benefits.map((benefit, index) => (
                            <motion.div
                                key={index}
                                className="benefit-card"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.6, delay: index * 0.1 }}
                            >
                                <div className="benefit-icon">{benefit.icon}</div>
                                <h4 className="benefit-title">{benefit.title}</h4>
                                <p className="benefit-text">{benefit.text}</p>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default WhyUs;
