import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Globe2, Shield } from "lucide-react";
import { InlineWidget } from "react-calendly";
import "./bookcall.css";

function BookCall() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path, sectionId = null) => {
        if (location.pathname !== path) {
            navigate(path);

            if (sectionId) {
                setTimeout(() => {
                    const section = document.getElementById(sectionId);
                    if (section) {
                        section.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 300);
            }
        } else if (sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <section id="bookcall" className="bookcall">
            <div className="bookcall-hero-container">
                <div className="bookcall-hero-content">
                    <h1>Secure Your Future in Web3—One Call Away</h1>
                    <p>
                        Discover tailored legal and financial solutions to confidently navigate the Web3 landscape.
                    </p>
                    <p>
                        Our expert team is here to simplify complexities, protect your assets, and help you thrive in a decentralized world.
                    </p>
                </div>

                {/* Booking Widget */}
                <div className="booking-container">
                    <div className="widget-wrapper">
                        <InlineWidget
                            url="https://calendly.com/d/yzr-byq-qcn"
                            styles={{
                                height: "80vh",
                                width: "100%",
                            }}
                            pageSettings={{
                                backgroundColor: "transparent",
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: "#6b00ff",
                                textColor: "black",
                            }}
                        />
                    </div>


                    <div className="features">
                        <div className="feature-item">
                            <Globe2 className="icon" />
                            <span>Access world-class expertise, wherever you are.</span>
                        </div>
                        <div className="feature-item">
                            <Shield className="icon" />
                            <span>Your privacy and trust are at the core of everything we do.</span>
                        </div>
                    </div>
                </div>

                <button onClick={() => handleNavigation('/', 'whyus')} className="to-whyus-button">
                    Still not sure?
                </button>
            </div>
        </section>
    );
}

export default BookCall;
