import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div id="footer" className="footer">
            <p>&copy; 2025 Orbilaw. All rights reserved.</p>


        </div>
    );
};

export default Footer;
